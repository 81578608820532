import { useHamburger, useHamburgerProps } from './use-hamburger'

export type HamburgerProps = useHamburgerProps

const Hamburger = (props: HamburgerProps) => {
  const { className, styles, active, toggle, ariaLabel, ariaControls } =
    useHamburger(props)

  return (
    <button
      className={styles.base({ className })}
      type="button"
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={active}
      onClick={toggle}
    >
      <i aria-hidden="true" className={styles.bar()} />
    </button>
  )
}

export default Hamburger
