import { forwardRef } from 'react'
import * as DrawerPrimitive from '@radix-ui/react-dialog'
import { Text } from '@/components/ui/text'
import { Vector } from '@/components/ui/vector'
import { useDrawer, useDrawerProps } from './use-drawer'

export type DrawerContentProps = useDrawerProps

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  (props, forwardedRef) => {
    const {
      className,
      styles,
      hasHeader,
      title,
      description,
      children,
      isometric
    } = useDrawer(props)

    return (
      <DrawerPrimitive.Portal>
        <DrawerPrimitive.Overlay
          className={styles.overlay()}
          style={{ zIndex: 9998 }}
        />
        <DrawerPrimitive.Content
          ref={forwardedRef}
          style={{ zIndex: 9999 }}
          className={styles.content({ className })}
        >
          <DrawerPrimitive.Title className="hidden">
            {title}
          </DrawerPrimitive.Title>
          <div className={styles.scroll()}>
            {hasHeader && (
              <header className={styles.heading()}>
                <div>
                  {!!title && (
                    <Text
                      as="h3"
                      className={styles.title()}
                      fontSize="heading-xs"
                      font="rubik"
                      weight="bold"
                      secondColor="text-green-500"
                    >
                      {title}
                    </Text>
                  )}
                  {!!description && (
                    <Text
                      as="p"
                      className={styles.description()}
                      color="text-gray-400"
                    >
                      {description}
                    </Text>
                  )}
                </div>
              </header>
            )}
            <DrawerPrimitive.Close aria-label="Close" asChild>
              <button className={styles.close()}>
                <Vector name="close" className={styles.icon()} />
              </button>
            </DrawerPrimitive.Close>
            <div className={styles.body()}>{children}</div>
          </div>
          {isometric && (
            <div className={styles.background()}>
              <Vector name="brand-arrow-2" className={styles.arrow()} />
            </div>
          )}
        </DrawerPrimitive.Content>
      </DrawerPrimitive.Portal>
    )
  }
)

DrawerContent.displayName = 'DrawerContent'

export const Drawer = DrawerPrimitive.Root
export const DrawerTrigger = DrawerPrimitive.Trigger
