import { tv, VariantProps } from '@/utils/tv'

export const badge = tv({
  slots: {
    base: ['relative', 'inline-flex', 'items-center', 'shrink-0', 'gap-x-3'],
    badge: [
      'flex',
      'z-10',
      'flex-wrap',
      'rounded-full',
      'whitespace-nowrap',
      'place-content-center',
      'origin-center',
      'items-center',
      'text-inherit',
      'select-none',
      'font-medium',
      'self-center',
      'shrink-0',
      'font-rubik'
    ]
  },
  variants: {
    size: {
      sm: {
        badge: 'px-1 text-badge-sm h-4'
      },
      md: {
        badge: 'px-1.5 text-badge-md h-6'
      },
      lg: {
        badge: 'px-2 text-badge-lg h-8'
      }
    },
    color: {
      dark: {
        badge: 'bg-gray-800 text-white'
      },
      light: {
        badge: 'bg-white text-gray-800'
      },
      primary: {
        badge: 'bg-green-500 text-white'
      },
      secondary: {
        badge: 'bg-green-800 text-white'
      }
    },
    placement: {
      right: '',
      left: {
        base: 'flex-row-reverse'
      }
    },
    isOneChar: {
      true: {
        badge: ''
      }
    }
  },
  defaultVariants: {
    size: 'md',
    color: 'primary',
    placement: 'right'
  },
  compoundVariants: [
    {
      isOneChar: true,
      size: 'sm',
      class: {
        badge: 'w-4'
      }
    },
    {
      isOneChar: true,
      size: 'md',
      class: {
        badge: 'w-6'
      }
    },
    {
      isOneChar: true,
      size: 'lg',
      class: {
        badge: 'w-8'
      }
    }
  ]
})

export type BadgeVariantProps = VariantProps<typeof badge>
