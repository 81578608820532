import { tv, VariantProps } from '@/utils/tv'

export const header = tv({
  slots: {
    base: ['fixed left-0 top-0 z-100', 'w-full', 'bg-white'],
    bar: [
      'relative',
      'flex items-center justify-between',
      'h-20',
      'px-6 lg:px-8',
      'border-b border-b-transparent',
      'transition-[border-color] duration-300'
    ],
    brandLink: [
      'flex items-center justify-between',
      'mobile:outline-none',
      'focus:outline-dotted focus:outline-gray-400/30 focus:outline-offset-6',
      'mr-8'
    ],
    brand: ['w-32', 'outline-none'],
    nav: [
      'w-full',
      'flex flex-row items-center justify-between',
      'mobile:bg-white',
      'mobile:flex-col mobile:items-start mobile:gap-6 desktop:gap-4',
      'mobile:absolute mobile:z-50 mobile:left-0 mobile:top-full',
      'mobile:pt-6 mobile:pb-6 mobile:px-6',
      'mobile:transition-all mobile:duration-300',
      'mobile:h-[calc(100vh-80px)]',
      'mobile:h-[calc(100dvh-80px)]'
    ],
    group: [
      'mobile:w-full',
      'mobile:relative',
      'mobile:first-of-type:overflow-y-auto',
      'mobile:first-of-type:pb-14',
      'mobile:last-of-type:before:content[""]',
      'mobile:last-of-type:before:absolute',
      'mobile:last-of-type:before:h-20',
      'mobile:last-of-type:before:z-50',
      'mobile:last-of-type:before:bg-gradient-to-t',
      'mobile:last-of-type:before:from-white',
      'mobile:last-of-type:before:pointer-events-none',
      'mobile:last-of-type:before:w-[calc(100%-10px)]',
      'mobile:last-of-type:before:bottom-[calc(100%+1.5rem)]'
    ],
    list: ['flex items-center', 'mobile:flex-col mobile:items-start'],
    item: ['mobile:w-full', 'desktop:mt-auto'],
    link: [],
    dropdown: [
      'desktop:bg-white',
      'desktop:data-[state=open]:animate-scaleIn desktop:data-[state=closed]:animate-scaleOut',
      'desktop:fixed desktop:top-[70px] desktop:inset-x-0 desktop:z-40',
      'origin-[top_center]',
      'mobile:mt-4',
      'desktop:mx-auto',
      'desktop:rounded-xl',
      'desktop:w-[calc(100%-24px)]'
    ],
    indicator: [
      'data-[state=visible]:animate-fadeIn',
      'data-[state=hidden]:animate-fadeOut',
      'top-[calc(100%_+_9px)] z-50',
      'flex h-[10px] items-end justify-center',
      'overflow-hidden',
      'transition-[width,transform_250ms_ease]'
    ],
    arrow: [
      'relative top-full',
      'rotate-[45deg]',
      'rounded-sm',
      'border-t border-l border-gray-100',
      'bg-white',
      'hidden desktop:flex',
      'h-4 w-4'
    ],
    cards: [
      'grid desktop:grid-cols-4 gap-5',
      'desktop:p-5',
      'desktop:border desktop:border-gray-100',
      'desktop:rounded-xl',
      'desktop:shadow-xl',
      'w-full'
    ]
  },
  variants: {
    opened: {
      false: {
        nav: ['mobile:-translate-x-full']
      },
      true: {
        base: ['z-[9999]'],
        nav: ['mobile:translate-x-0']
      }
    },
    affixed: {
      false: {
        bar: []
      },
      true: {
        bar: ['border-b-gray-100']
      }
    }
  },
  defaultVariants: {
    opened: false,
    affixed: false
  }
})

export type HeaderVariantProps = VariantProps<typeof header>

export type HeaderReturnVariantProps = ReturnType<typeof header>
