import { NextLink } from '@/utils/navigation'
import { Vector } from '@/components/ui/vector'
import { HeaderReturnVariantProps } from './header.variants'
import { useHeaderState } from './use-header-state'

type HeaderBrandProps = {
  styles: HeaderReturnVariantProps
}

const HeaderBrand = ({ styles }: HeaderBrandProps) => {
  const { closeMenu } = useHeaderState()

  return (
    <NextLink
      href="/"
      onClick={closeMenu}
      className={styles.brandLink()}
      title="Execut"
      prefetch={false}
    >
      <Vector name="brand-name" className={styles.brand()} />
    </NextLink>
  )
}

export default HeaderBrand
