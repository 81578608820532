import { useMemo, useRef } from 'react'
import { header } from './header.variants'
import { useHeaderState } from './use-header-state'

export const useHeader = () => {
  const { opened, affixed } = useHeaderState()
  const headerRef = useRef(null)

  const styles = useMemo(() => header({ opened, affixed }), [opened, affixed])

  return {
    headerRef,
    styles
  }
}
