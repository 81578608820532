import { drawer, DrawerVariantProps } from './drawer.variants'

export type useDrawerProps = DrawerVariantProps & {
  className?: string
  title?: string
  description?: string
  isometric?: boolean
  children: React.ReactNode
  hideTitle?: boolean
}

export const useDrawer = (props: useDrawerProps) => {
  const {
    className = '',
    isometric = false,
    title,
    description,
    hideTitle = false,
    children
  } = props

  const hasHeader = (!!title || !!description) && !hideTitle
  const styles = drawer({ hasHeader })

  return {
    className,
    styles,
    hasHeader,
    isometric,
    title,
    description,
    children
  }
}
