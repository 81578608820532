import {
  Item as NavItem,
  Trigger as NavTrigger,
  Link as NavLink,
  Content as NavContent
} from '@radix-ui/react-navigation-menu'
import { Link } from '@/components/ui/link'
import { Button } from '@/components/ui/button'
import { ServiceCard } from '@/components/features/services/service-card'
import { HeaderReturnVariantProps } from './header.variants'
import { MenuItemProps } from './header.menu'
import { useHeaderState } from './use-header-state'

type HeaderMenuItemProps = {
  className?: string
  styles: HeaderReturnVariantProps
  messages: Pick<Messages, 'menu' | 'services'>
} & MenuItemProps

const HeaderMenuItem = ({
  styles,
  className = '',
  messages,
  href,
  title,
  variant = 'link',
  color = 'dark',
  fullWidth = false,
  icon,
  target,
  dropdown = []
}: HeaderMenuItemProps) => {
  const { closeMenu, cardVariant } = useHeaderState()
  const hasDropdown = dropdown.length > 0

  const getItemClassName = () => {
    if (title === 'talk') {
      return `${className} md:hidden 1xl:block`
    }

    return className
  }

  return (
    <NavItem className={styles.item({ className: getItemClassName() })}>
      {!hasDropdown ? (
        <NavLink asChild>
          <Link
            className={styles.link({ className: 'mobile:flex' })}
            href={href}
            title={messages.menu[title]}
            color={color}
            variant={variant}
            fullWidth={fullWidth}
            onClick={closeMenu}
            prefetch={true}
            target={target}
            startIcon={icon}
          >
            {messages.menu[title]}
          </Link>
        </NavLink>
      ) : (
        <>
          <NavTrigger asChild>
            <Button
              type="button"
              color={color}
              variant={variant}
              className="group"
              endIcon="chevron-down"
              endIconClassName="transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
              isBetween
            >
              {messages.menu[title]}
            </Button>
          </NavTrigger>
          <NavContent className={styles.dropdown()}>
            <div className={styles.cards()}>
              {dropdown.map((item) => (
                <NavLink key={item.icon} onClick={closeMenu} asChild>
                  <ServiceCard
                    variant={cardVariant}
                    icon={item.icon}
                    title={messages.services[item.title].title}
                    description={messages.services[item.title].description}
                    url={item.href}
                  />
                </NavLink>
              ))}
            </div>
          </NavContent>
        </>
      )}
    </NavItem>
  )
}

export default HeaderMenuItem
