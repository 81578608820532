import { useMemo } from 'react'
import { BadgeVariantProps, badge } from './badge.variants'

export type useBadgeProps = Omit<BadgeVariantProps, 'isOneChar'> & {
  className?: string
  content?: string | number | React.ReactNode
  children: React.ReactNode
}

export const useBadge = (props: useBadgeProps) => {
  const {
    className = '',
    children,
    content,
    size = 'sm',
    color = 'primary',
    placement = 'right',
    ...componentProps
  } = props

  const isOneChar = useMemo(() => String(content)?.length === 1, [content])

  const styles = useMemo(
    () => badge({ size, color, placement, isOneChar }),
    [size, color, placement, isOneChar]
  )

  return {
    className,
    children,
    content,
    styles,
    componentProps
  }
}
