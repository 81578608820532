import { tv, VariantProps } from '@/utils/tv'

export const drawer = tv({
  slots: {
    base: [],
    overlay: [
      'data-[state=open]:animate-overlayShow',
      'fixed inset-0',
      'bg-gray-800/60'
    ],
    content: [
      'data-[state=open]:animate-enterFromRight',
      'data-[state=closed]:animate-exitToRight',
      'fixed top-0 right-0',
      'w-full h-full max-w-[540px]',
      'bg-white',
      'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
      'focus:outline-none'
    ],
    scroll: ['w-full h-full', 'overflow-y-auto'],
    heading: ['flex justify-between gap-6'],
    title: [],
    description: ['mt-2'],
    body: ['relative'],
    close: [
      'absolute top-4 right-4 z-20',
      'flex items-center justify-center shrink-0',
      'h-8 w-8',
      'appearance-none',
      'rounded-full',
      'border border-gray-100',
      'text-gray-400 bg-gray-10 hover:bg-gray-50',
      'trasition-colors duration-300',
      'focus:outline-dotted focus:outline-offset-2 focus:outline-gray-200/30'
    ],
    icon: ['w-2.5 h-2.5'],
    background: [
      'absolute bottom-0 right-0 -z-10',
      'w-full h-24',
      'overflow-hidden'
    ],
    arrow: ['w-[800px] h-[992px]']
  },
  variants: {
    hasHeader: {
      false: {
        scroll: []
      },
      true: {
        scroll: ['flex flex-col gap-6', 'px-10 md:px-12 py-10']
      }
    }
  },
  defaultVariants: {
    hasHeader: false
  }
})

export type DrawerVariantProps = VariantProps<typeof drawer>
