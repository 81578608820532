'use client'

import {
  Root as NavRoot,
  List as NavList,
  Indicator as NavIndicator
} from '@radix-ui/react-navigation-menu'
import { menu } from './header.menu'
import { HeaderReturnVariantProps } from './header.variants'
import HeaderMenuItem from './header.menu-item'
import HeaderMenuFavorite from './header.menu-favorite'

type HeaderNavProps = {
  styles: HeaderReturnVariantProps
  messages: Pick<Messages, 'menu' | 'services'>
}

const HIDE_ON_DESKTOP = ['about', 'work_us']

const HeaderNav = ({ styles, messages }: HeaderNavProps) => {
  const siteMenu = menu.slice(0, menu.length - 2)
  const utilMenu = menu.slice(menu.length - 2, menu.length)

  const headerMenuItemClassNames = (title: string) => {
    let className = `mobile:border-b mobile:border-gray-100 mobile:pb-4`

    if (HIDE_ON_DESKTOP.includes(title)) {
      className += ` desktop:hidden `
    }

    return className
  }

  return (
    <NavRoot className={styles.nav()}>
      <div className={styles.group({ className: 'w-full' })}>
        <NavList
          className={styles.list({
            className: '1xl:gap-8 gap-4 desktop:gap-6'
          })}
        >
          {siteMenu.map((item) => (
            <HeaderMenuItem
              key={item.title}
              styles={styles}
              className={headerMenuItemClassNames(item.title)}
              messages={messages}
              {...item}
            />
          ))}
          <HeaderMenuFavorite styles={styles} title={messages.menu.favorites} />
          <NavIndicator className={styles.indicator()}>
            <div className={styles.arrow()} />
          </NavIndicator>
        </NavList>
      </div>
      <div className={styles.group({ className: 'md:hidden lg:block' })}>
        <ul
          className={styles.list({ className: 'desktop:gap-3 mobile:gap-4' })}
        >
          {utilMenu.map((item) => (
            <HeaderMenuItem
              key={item.title}
              styles={styles}
              messages={messages}
              {...item}
            />
          ))}
        </ul>
      </div>
    </NavRoot>
  )
}

export default HeaderNav
