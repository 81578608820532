import { useEffect, useState } from 'react'
import { Item as NavItem } from '@radix-ui/react-navigation-menu'
import { Badge } from '@/components/ui/badge'
import { Link } from '@/components/ui/link'
import { HeaderReturnVariantProps } from './header.variants'
import { useHeaderState } from './use-header-state'
import { useFavorites } from '@/hooks/use-favorites'

type HeaderMenuFavoriteProps = {
  styles: HeaderReturnVariantProps
  title: string
}

const HeaderMenuFavorite = ({ styles, title }: HeaderMenuFavoriteProps) => {
  const [total, setTotal] = useState(0)

  const { closeMenu } = useHeaderState()
  const { properties } = useFavorites()

  useEffect(() => {
    setTotal(properties.length)
  }, [properties])

  return (
    <NavItem
      className={styles.item({ className: 'desktop:ml-auto desktop:mr-2' })}
    >
      <Badge size="md" color="primary" content={total} placement="right">
        <Link href="/favoritos" variant="link" color="dark" onClick={closeMenu}>
          {title}
        </Link>
      </Badge>
    </NavItem>
  )
}

export default HeaderMenuFavorite
