'use client'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/data/input'
import { TextArea } from '@/components/data/textarea'
import { Drawer, DrawerContent } from '@/components/common/drawer'
import {
  useConversionForm,
  type useConversionFormProps
} from './use-conversion-form'

type ConversionFormContentProps = useConversionFormProps

const ConversionFormContent = (props: ConversionFormContentProps) => {
  const {
    field,
    send,
    util,
    onSubmit,
    register,
    formState,
    conversion,
    opened,
    closeForm
  } = useConversionForm(props)
  const { errors, isSubmitting } = formState

  return (
    <Drawer open={opened} onOpenChange={closeForm}>
      <DrawerContent
        title={conversion.title}
        description={conversion.description}
        isometric
      >
        <form noValidate onSubmit={onSubmit} className="grid grid-cols-1 gap-6">
          <Input {...register('name')} label={field.name} error={errors.name} />
          <Input
            {...register('email')}
            type="email"
            label={field.email}
            error={errors.email}
          />
          <Input
            {...register('phone')}
            type="tel"
            mask="globalphone"
            label={field.phone}
            error={errors.phone}
          />
          <TextArea
            {...register('message')}
            label={field.message}
            rows={3}
            error={errors.message}
          />
          <Button
            className="mt-2"
            type="submit"
            variant="solid"
            color="primary"
            size="xl"
            fullWidth
            loading={isSubmitting}
            loadingLabel={util.loading}
          >
            {send}
          </Button>
        </form>
      </DrawerContent>
    </Drawer>
  )
}

export default ConversionFormContent
