/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { useWindowSize } from 'react-use'
import { HeaderContext, HeaderStateProps } from './header.context'
import { i18n } from '@/config/locales'

const DESKTOP_MAX_SCREEN = 1025

export const useHeaderState = () => {
  const { state, setState } = useContext(HeaderContext)
  const { width } = useWindowSize()
  const { scrollY } = useScroll()
  const pathname = usePathname()

  const isHome = i18n.locales.some(
    (locale) => `/${locale}` === pathname || pathname === '/'
  )

  const { opened, affixed, cardVariant } = state

  const dispatch = useCallback(
    (payload: Partial<HeaderStateProps>) => {
      setState((state) => ({ ...state, ...payload }))
    },
    [state]
  )

  const onToggleMenu = useCallback(
    (opened: boolean) => dispatch({ opened }),
    [dispatch]
  )

  const closeMenu = () => {
    opened && onToggleMenu(false)
  }

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (opened || !isHome) return
    affixOnScroll(latest)
  })

  useEffect(() => {
    dispatch({ affixed: !isHome })
  }, [isHome])

  useEffect(() => {
    let newState: Record<string, any> = {}

    const isMediumCard = cardVariant === 'medium'

    if (width >= DESKTOP_MAX_SCREEN) {
      if (!isMediumCard) {
        newState.cardVariant = 'medium'
      }

      if (opened) {
        newState.opened = false
      }

      dispatch(newState)
    } else {
      if (isMediumCard) {
        newState.cardVariant = 'tiny'
        dispatch(newState)
      }
    }
  }, [width])

  const affixOnScroll = useCallback(
    (latest: number) => {
      const isScrollDownEnough = latest >= 5

      if (affixed !== isScrollDownEnough) {
        dispatch({ affixed: isScrollDownEnough })
      }
    },
    [scrollY, affixed]
  )

  return {
    opened,
    affixed,
    cardVariant,
    dispatch,
    onToggleMenu,
    closeMenu
  }
}
