'use client'

import {
  type Dispatch,
  type PropsWithChildren,
  createContext,
  useEffect,
  useReducer
} from 'react'
import type { ActionMap } from '@/types'
import { AdvertiseSchema } from '@/types'
import { isBrowser } from '@/utils/env'
import { ADVERTISE_STORAGE_KEY } from '@/config/advertise'
import { usePathname, useRouter } from '@/utils/navigation'

export enum AdvertiseAction {
  PREV_STEP = 'PREV_STEP',
  NEXT_STEP = 'NEXT_STEP',
  SET_STEP = 'SET_STEP',
  SET_TOTAL_STEPS = 'SET_TOTAL_STEPS',
  UPDATE_DATA = 'UPDATE_DATA',
  SET_SUBMITTING = 'SET_SUBMITTING',
  CLEAR = 'CLEAR'
}

export type AdvertisePayload = {
  [AdvertiseAction.PREV_STEP]: undefined
  [AdvertiseAction.NEXT_STEP]: undefined
  [AdvertiseAction.SET_STEP]: number
  [AdvertiseAction.SET_TOTAL_STEPS]: number
  [AdvertiseAction.SET_SUBMITTING]: boolean
  [AdvertiseAction.UPDATE_DATA]: Partial<AdvertiseSchema>
  [AdvertiseAction.CLEAR]: undefined
}

export type AdvertiseActions =
  ActionMap<AdvertisePayload>[keyof ActionMap<AdvertisePayload>]

export type AdvertiseState = {
  currentStep: number
  totalSteps: number
  isSubmitting?: boolean
  data: Partial<AdvertiseSchema>
}

export const initialAdvertiseState: AdvertiseState = {
  currentStep: 0,
  totalSteps: 0,
  isSubmitting: false,
  data: {}
}

export const advertiseReducer = (
  state: AdvertiseState,
  action: AdvertiseActions
) => {
  switch (action.type) {
    case AdvertiseAction.PREV_STEP:
      return { ...state, currentStep: state.currentStep - 1 }
    case AdvertiseAction.NEXT_STEP:
      return { ...state, currentStep: state.currentStep + 1 }
    case AdvertiseAction.SET_STEP:
      if (action.payload === state.currentStep) {
        return state
      }

      return { ...state, currentStep: action.payload }
    case AdvertiseAction.SET_TOTAL_STEPS:
      return { ...state, totalSteps: action.payload }
    case AdvertiseAction.UPDATE_DATA:
      return { ...state, data: { ...state.data, ...action.payload } }
    case AdvertiseAction.CLEAR:
      return {
        ...state,
        isSubmitting: false,
        data: {
          name: state.data.name,
          email: state.data.email,
          phone: state.data.phone,
          postalcode: state.data.postalcode
        }
      }
    case AdvertiseAction.SET_SUBMITTING:
      return { ...state, isSubmitting: action.payload }
    default:
      return state
  }
}

export const AdvertiseContext = createContext<{
  state: AdvertiseState
  dispatch: Dispatch<AdvertiseActions>
}>({
  state: initialAdvertiseState,
  dispatch: () => {}
})

const initializer = (initialValue = initialAdvertiseState) => {
  if (!isBrowser) return initialValue

  const data = sessionStorage.getItem(ADVERTISE_STORAGE_KEY)

  if (!data) {
    return initialValue
  }

  return JSON.parse(data)
}

export const AdvertiseProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname()
  const router = useRouter()

  const [state, dispatch] = useReducer(
    advertiseReducer,
    initialAdvertiseState,
    initializer
  )

  useEffect(() => {
    const isInvalidUser = !state.data.name || !state.data.email

    if (pathname.includes('/detalhes') && isInvalidUser) {
      router.push('/anuncie-seu-imovel')
    }
  }, [pathname, router, state.data.email, state.data.name])

  useEffect(() => {
    if (state.totalSteps === 0) {
      return
    }

    if (state.currentStep < 0 || state.currentStep >= state.totalSteps) {
      router.push('/anuncie-seu-imovel')
    }
  }, [router, state.currentStep, state.totalSteps])

  useEffect(() => {
    if (isBrowser) {
      const encodedState = JSON.stringify(state)
      sessionStorage.setItem(ADVERTISE_STORAGE_KEY, encodedState)
    }
  }, [state])

  return (
    <AdvertiseContext.Provider value={{ state, dispatch }}>
      {children}
    </AdvertiseContext.Provider>
  )
}
