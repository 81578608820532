import { ConversionSchema, Locale } from '@/types'
import { CockpitFormOperation } from '@/services/types'
import { urlWithQueryParams } from '@/utils/url'
import { cockpitFetch } from '../client'

const postLead = async ({
  locale,
  data
}: {
  locale: Locale
  data: ConversionSchema
}): Promise<CockpitFormOperation> => {
  try {
    const res = await cockpitFetch<CockpitFormOperation>({
      method: 'POST',
      resource: urlWithQueryParams(`conversion`, { locale }),
      payload: JSON.stringify({
        ...data,
        building: data.reference,
        origin: 'site',
        identifier: 'conversao-site-execut',
        accept_terms: true,
        accept_communications: false
      })
    })

    return res?.body
  } catch (error) {
    throw error
  }
}

export { postLead }
