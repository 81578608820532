import { useBadge, useBadgeProps } from './use-badge'

export type BadgeProps = useBadgeProps

const Badge = (props: BadgeProps) => {
  const { className, children, content, styles, componentProps } =
    useBadge(props)

  return (
    <div className={styles.base({ className })} {...componentProps}>
      {children}
      <span className={styles.badge()}>{content}</span>
    </div>
  )
}

export default Badge
