'use client'

import { Toaster } from 'sonner'
import { ConversionProvider } from '@/contexts/conversion'
import { FavoritesProvider } from '@/contexts/favorites'
import { AdvertiseProvider } from '@/contexts/advertise'

type ProvidersProps = {
  children: React.ReactNode
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <>
      <ConversionProvider>
        <AdvertiseProvider>
          <FavoritesProvider>{children}</FavoritesProvider>
        </AdvertiseProvider>
      </ConversionProvider>
      <Toaster
        position="bottom-center"
        visibleToasts={1}
        toastOptions={{
          duration: 2000
        }}
        richColors
      />
    </>
  )
}

export default Providers
